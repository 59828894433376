import React from 'react'
import './RelatedProducts.scss'

import Products from '../../Products/Products'

function RelatedProducts({relatedProducts}) {
  return (
    <div className='related-products'>
      <Products headingText="Related Products" products={relatedProducts}/>
    </div>
  )
}

export default RelatedProducts