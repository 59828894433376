import React from 'react'
import { Box } from '@mui/material'
import Header from '../../components/Header'
import Geo from '../../components/Geo'
import {useTheme} from '@mui/material'
import {tokens} from '../../../../../theme'

function Geography() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
        <Header title="Line Chart" subtitle="Simple Line Chart"/>
        <Box 
          height="75vh" 
          sx={{boxShadow:`0px 2.98256px 7.4564px ${colors.gray[1000]}`,}}
          borderRadius="10px"
        >
            <Geo/>
        </Box>
    </Box>
  )
}

export default Geography