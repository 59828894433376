import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBPJ2rj1aj-ebFhbW_JRrDnXJjt_9E81jU",
    authDomain: "e-shop-c8e04.firebaseapp.com",
    databaseURL: "https://e-shop-c8e04-default-rtdb.firebaseio.com",
    projectId: "e-shop-c8e04",
    storageBucket: "e-shop-c8e04.appspot.com",
    messagingSenderId: "1010064006655",
    appId: "1:1010064006655:web:a29ac60311d597cc845bca",
    measurementId: "G-866DJW47NK"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

const fetchData = async (collection) => {
    const data = await db.collection(collection).get();
    const dataArray = data.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return dataArray;
}

export { db, auth, fetchData};