import React from 'react'
import './Newsletter.scss'

import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn
} from 'react-icons/fa'

function Newsletter() {
  return (
    <div className='newsletter--section'>
      <div className="newsletter--content">
        <div className="small--text">Newsletter</div>
        <span className="big--text">Sign up for latest updates and offers</span>
        <div className="form">
          <input type="text" placeholder='Email Address' />
          <button>Subscribe</button>
        </div>
        <div className="text">Will be used in accordance with our Privacy Policy</div>
        <div className="social--icons">
          <div className="icon">
            <FaFacebook/>
          </div>
          <div className="icon">
            <FaTwitter/>
          </div>
          <div className="icon">
            <FaInstagram/>
          </div>
          <div className="icon">
            <FaLinkedinIn/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Newsletter