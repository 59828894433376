import {useState, useEffect} from 'react'
import { fetchData} from "../utils/Firebase";

const useFetch = (collection) => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        setData(null);
        setError(null);

        fetchData(collection).then((data) => {
            setLoading(false);
            setData(data);
        })
        .catch((error) => {
            setLoading(false);
            setError(error);
        });
    }, [collection]);

    return { data, loading, error };
};

export default useFetch;

