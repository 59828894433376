import React from 'react'
import { useContext } from 'react'
import './SingleProduct.scss'

import {
  FaFacebook, 
  FaTwitter, 
  FaInstagram, 
  FaLinkedinIn, 
  FaPinterest, 
  FaCartPlus
} from 'react-icons/fa'
import RelatedProducts from '../SingleProduct/RelatedProducts/RelatedProducts'
import {useParams} from 'react-router-dom'
import {db} from '../../utils/Firebase'
import {useState, useEffect} from 'react'
import {Context} from '../../utils/Context'


function SingleProduct() {

  const [quantity, setQuantity] = useState(1);
  const {id} = useParams()

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const {handleAddToCart} = useContext(Context);

  const [relatedProducts, setRelatedProducts] = useState(null);
  const [bigImg, setBigImg] = useState(null);

  const decrement = () => {
    setQuantity((prevState) => {
        if (prevState === 1) return 1;
        return prevState - 1;
    });
  };
  const increment = () => {
      setQuantity((prevState) => prevState + 1);
  };

  useEffect(() => {
      setLoading(true);
      setData(null);
      setError(null);

      db.collection('Products').doc(id).get().then((doc) => {
        if (doc.exists) {
          setLoading(false);
          setData(doc.data());
          setBigImg(doc.data().images[0]);

          db.collection('Products').where('category', '==', doc.data().category).get().then((data) => {
            const dataArray = data.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setRelatedProducts(dataArray);
          })
        }
        else{
          setError('Product not found');
          setLoading(false);
        }
      })
      .catch((error) => {
          setLoading(false);
          setError(error);
      });
  }, [id]);


  return (
    loading ? 
    <div className="loading">
      Loading...
    </div> 
    : error ? <div className="error">{error}</div> 
    : data &&
    <div className='single-product-main-coontent'>
      <div className="layout">
        <div className="single-product-page">

          <div className="left">
            <div className="images--list">
              {data.images.map((image, index) => {
                  return (
                  <div className='image' onClick={() => {setBigImg(image)}}>
                    <img src={image} alt=""/>
                  </div>
                )
              })}
            </div>
            <img src={bigImg} alt="" />
          </div>

          <div className="right">
            <span className="name">{data.name}</span>
            <span className="price">&#8377;{data.price}</span>
            <span className="desc">{data.description}</span>

            <div className="cart-buttons">
              <div className="quantity-buttons">
                <span onClick={() => decrement()}>-</span>
                <span>{quantity}</span>
                <span onClick={() => increment()}>+</span>
              </div>
              <button className='add-to-cart-button' onClick={() => {
                handleAddToCart(data, quantity)
              }}>
                <FaCartPlus size={20}/>
                ADD TO CART
              </button>
            </div>

            <span className="divider"/>

            <div className="info-item">
              <span className="text-bold">
                Category: 
                <span> Headphones</span>
              </span>
              <span className="text-bold">
                Share:
                <span className='social-icons'>
                  <FaFacebook/>
                  <FaTwitter/>
                  <FaInstagram/>
                  <FaLinkedinIn/>
                  <FaPinterest/>
                </span>
              </span>
            </div>

          </div>
        </div>
        <RelatedProducts relatedProducts={relatedProducts}/>
      </div>
    </div>
  )
}

export default SingleProduct