import React from 'react'
import './SellerHome.scss'
import Banner from './banner/Banner'
import Details from './details/Details'
import Header from './header/Header'

function SellerHome() {
  return (
    <div>
        <Header />
        <Banner />
        <Details />
    </div>
  )
}

export default SellerHome