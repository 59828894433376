import React from 'react'
import './Home.scss'

import Banner from './Banner/Banner'
import Category from './Category/Category'
import Products from '../Products/Products'
import useFetch from '../../hooks/UseFetch'

function Home() {

  const {data, loading, error} = useFetch("Products")

  return (
    <div>
      <Banner />
      <div className="main--content">
        <div className="layout">
          <Category/>
          <Products headingText="Popular Products" products={data}/>
        </div>
      </div>
    </div>
  )
}

export default Home