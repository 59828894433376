import React from 'react'
import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../../../../theme";

function Faq() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions Page" />

      <Accordion defaultExpanded sx={{boxShadow:`0px 2.98256px 7.4564px ${colors.gray[1000]}`}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
        sx={{backgroundColor: colors.primary[400]}}
        >
          <Typography color={colors.greenAccent[500]} variant="h6">
            An Important Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded sx={{boxShadow:`0px 2.98256px 7.4564px ${colors.gray[1000]}`}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{backgroundColor: colors.primary[400]}}>
          <Typography color={colors.greenAccent[500]} variant="h6">
            Another Important Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded sx={{boxShadow:`0px 2.98256px 7.4564px ${colors.gray[1000]}`}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{backgroundColor: colors.primary[400]}}>
          <Typography color={colors.greenAccent[500]} variant="h6">
            Your Favorite Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded sx={{boxShadow:`0px 2.98256px 7.4564px ${colors.gray[1000]}`}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{backgroundColor: colors.primary[400]}}>
          <Typography color={colors.greenAccent[500]} variant="h6">
            Some Random Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded sx={{boxShadow:`0px 2.98256px 7.4564px ${colors.gray[1000]}`}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{backgroundColor: colors.primary[400]}}>
          <Typography color={colors.greenAccent[500]} variant="h6">
            The Final Question
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default Faq