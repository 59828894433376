import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './components/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer'
import Category from './components/Category/Category'
import SingleProduct from './components/SingleProduct/SingleProduct'
import Newsletter from './components/Footer/Newsletter/Newsletter'
import SignUp from './components/signup/SignUp';
import SignIn from './components/signin/SignIn';
import SellerHome from './components/seller/sellerHome/SellerHome';
import Registration from './components/seller/registration/Registration';
import AdminPanel from './components/seller/sellerDashBoard/AdminPanel';

import  AppContext  from './utils/Context'

function App() {

  return (
        <div className="App">
          <BrowserRouter>
          <AppContext>
            <Routes>
              <Route path="/" element={
                <>
                  <Header />
                  <Home/>
                  <Newsletter />
                  <Footer />
                </>
              } />
              <Route path="/category/:id" element={
                <>
                  <Header />
                  <Category/>
                  <Newsletter />
                  <Footer/>
                </>
              } />
              <Route path="/product/:id" element={
                <>
                  <Header />
                  <SingleProduct/>
                  <Newsletter />
                  <Footer/>
                </>
              } />
              <Route path="*" element={<h1>Not Found</h1>} />
              <Route path='/signin' element={<SignIn/>} />
              <Route path='/signup' element={<SignUp/>} />
              <Route path='/become-a-seller' element={<SellerHome/>}/>
              <Route path='/become-a-seller/registration' element={<Registration/>}/>
              <Route path='/admin-panel' element={<AdminPanel/>}/>
            </Routes>
          </AppContext>
          </BrowserRouter>
        </div>
  );
}

export default App;
