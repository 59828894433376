import React, {useContext} from 'react'
import './Header.scss'

import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {TbSearch} from "react-icons/tb"
import {CgShoppingCart} from "react-icons/cg"
import {AiOutlineHeart} from "react-icons/ai"

import Search from '../Header/Search/Search'
import Cart from '../Cart/Cart'
import { Context } from '../../utils/Context'
import { auth } from '../../utils/Firebase'

function Header() {

  const {cartCount, user, setUser, setCartCount,setCartItems} = useContext(Context);

  const handleAuth = () => {
    if (user) {
        auth.signOut()
        setCartItems([]);
    }else{
        navigate('/signin')
    }
  }

  const navigate = useNavigate()

  const [scrolled, setScrolled] = useState(false)
  const [showCart, setShowCart] = useState(false)
  const [showSearch, setShowSearch] = useState(false)

  const handleScroll = () => {
    const offset = window.scrollY
    if(offset > 200) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    auth.onAuthStateChanged((authUser) => {
      if(authUser){
        setUser(authUser)
      }else{
        setUser(null)
      }
    })
  }, [setUser])


  return (
    <>
      <header className={`main--header ${scrolled ? `sticky--header` : ""}`}>
        <div className="header--content">
          <ul className='left'>
            <li>Home</li>
            <li>About</li>
            <li>Categories</li>
          </ul>
          <div className="center" onClick={() => navigate('/')}>LESSOCOMMERSE</div>
          <div className="right">
            <TbSearch onClick={() => setShowSearch(!showSearch)}/>
            <AiOutlineHeart/>
            <span className='cart--icon' onClick={() => setShowCart(!showCart)}>
              <CgShoppingCart/>
              <span>{cartCount}</span>
            </span>
            <span className='sign--up' onClick={handleAuth}>{user ? 'Sign Out' : 'Sign In'}</span>
          </div>
        </div>
      </header>
      {showCart && <Cart setShowCart={setShowCart}/>}
      {showSearch && <Search setShowSearch={setShowSearch}/>}
    </>
  )
}

export default Header