import { useEffect } from "react";
import { createContext, useState } from "react";
import { useLocation } from "react-router-dom";

export const Context = createContext();

export const AppContext = ({ children }) => {
    const [categories, setCategories] = useState();
    const [products, setProducts] = useState();
    const [selected, setSelected] = useState('Dashboard')
    const [showCart, setShowCart] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [cartCount, setCartCount] = useState(0);
    const [cartSubTotal, setCartSubTotal] = useState(0);
    const [user, setUser] = useState(null);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        let count = 0;
        cartItems?.map((item) => (count += item.quantity));
        setCartCount(count);

        let subTotal = 0;
        cartItems.map(
            (item) =>
                (subTotal += item.price * item.quantity)
        );
        setCartSubTotal(subTotal);
    }, [cartItems]);

    const handleAddToCart = (product, quantity) => {
        if(!user){
            alert("Please login to add products to cart");
            return;
        }
        let items = [...cartItems];
        let index = items?.findIndex((p) => p.id === product?.id);
        if (index === -1) {
            items?.push({
                ...product,
                quantity: quantity,
            });
        } else {
            items[index].quantity += quantity;
        }
        setCartItems(items);
    };

    const handleRemoveFromCart = (product) => {
        let items = [...cartItems];
        let index = items?.findIndex((p) => p.id === product?.id);
        items?.splice(index, 1);
        setCartItems(items);
    };

    const handleCartProductQuantity = (type, product) => {
        let items = [...cartItems];
        let index = items?.findIndex((p) => p.id === product?.id);
        if (type === "inc") {
            items[index].quantity += 1;
        } else {
            items[index].quantity -= 1;
        }
        setCartItems(items);
    };

    const handleUser = (user) => {
        setUser(user);
    };

    return (
        <Context.Provider
            value={{
                products,
                setProducts,
                categories,
                setCategories,
                cartItems,
                setCartItems,
                handleAddToCart,
                cartCount,
                handleRemoveFromCart,
                showCart,
                setShowCart,
                handleCartProductQuantity,
                cartSubTotal,
                user,
                setUser,
                selected,
                setSelected,
            }}
        >
            {children}
        </Context.Provider>
    );
};


export default AppContext;