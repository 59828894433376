import React from 'react'
import './Category.scss'

import cat1 from '../../../assets/category/cat-1.jpg'
import useFetch from '../../../hooks/UseFetch'
import { useState } from 'react'

function Category() {

  const {data, loading, error} = useFetch("Products")

  const catProducts = data?.slice(0,4).map((product) => {
    return (
      <div className="category">
        <img src={product.images[0]} alt="" />
        <div className="category--name">{product.category}</div>
      </div>
    )
  })

  return (
    <div className='shop-by-category'>
      <div className="categories">

        {catProducts}
        
      </div>
    </div>
  )
}

export default Category