import React from 'react'
import './SignUp.scss'
import { auth } from '../../utils/Firebase'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function SignUp() {

  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirm] = useState('')

  const register = (e) =>{
      e.preventDefault()
      if(password !== confirmPassword) {
          alert("Passwords don't match")
          return
      }
      auth.createUserWithEmailAndPassword(email, password)
      .then((res) => {
          navigate('/')
      })
      .catch(error => alert(error.message))
  }

  return (
    <div className='signup--container'>
      <div className="logo" onClick={() => navigate('/')}>LESSO</div>
        <div className="signup--content">
            <div className="signup--heading">Sign In</div>
            <div className="signup--form">
                <div className="form--group">
                    <span>E-mail</span>
                    <input 
                      type="text" 
                      value={email} 
                      className='email'
                      onChange={
                          (e) => setEmail(e.target.value)
                      }
                    />
                    <span>Password</span>
                    <input 
                      type="password" 
                      className='password'
                      value={password}
                      onChange={
                          (e) => setPassword(e.target.value)
                      }
                    />
                    <span>Confirm Password</span>
                    <input 
                      type="password" 
                      className='confirm--password'
                      value={confirmPassword}
                      onChange={
                          (e) => setConfirm(e.target.value)
                      }
                    />
                    <button className='signup--btn' onClick={register}>Create Account</button>
                </div>
            </div>
            <p>By creating account you agree to Amazon's Conditions of Use & Sale. Please see our Privacy Notice, our Cookies Notice and our Interest-Based Ads Notice.</p>
            <button className="login--registerButton" onClick={() => navigate('/signin')}>Already have account? Sign In</button>
        </div>
    </div>
  )
}

export default SignUp