import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Product.scss'

function Product({id, title, price, image}) {

  const navigate = useNavigate()

  const goToTop = () => {
    window.scrollTo(0, 0)
  }
  
  return (
    <div className='product--card'>
      <div className="product--details">
        <img src={image} alt="" onClick={() => {navigate(`/product/${id}`); goToTop()}}/>
        <span className="name">{title}</span>
        <span className="price">&#8377;{price}</span>
      </div>
    </div>
  )
}

export default Product