import React from 'react'
import './Header.scss'
import {FaSearch} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

export default function Header() {

    const navigate = useNavigate()

    return (
        <header>
            <div className="header--container">
                <div className="header--content">
                    <span className='logo' onClick={() => navigate('/')}>LESSOCOMMERSE</span>
                    <div className="left">
                        <span className="text">Start</span>
                        <span className="text">Grow</span>
                        <span className="text">Learn</span>
                        <span className="text">Pricing</span>
                    </div>
                    <div className="right">
                        <button onClick={() => navigate('/become-a-seller/registration')}>Start Selling</button>
                        <FaSearch className='search--icon' />
                    </div>
                </div>
            </div>
        </header>
    )
}
