import React,{useContext} from 'react'
import './CartItem.scss'

import prod from '../../../assets/products/earbuds-prod-1.webp'
import {MdClose} from 'react-icons/md'
import {Context} from '../../../utils/Context'

function CartItem() {

  const {cartItems, handleCartProductQuantity, handleRemoveFromCart} = useContext(Context);

  return (
    <div className='cart-products'>
      {cartItems.map(item => (
        <div key={item.id} className="cart-product">
          <div className="img-container">
            <img src={item.images[0]} alt="" />
          </div>
          <div className="prod-details">
            <span className="name">{item.name}</span>
            <MdClose className='close-btn' onClick={() => handleRemoveFromCart()}/>
            <div className="quantity-buttons">
              <span onClick={() => handleCartProductQuantity('dec', item)}>-</span>
              <span>{item.quantity}</span>
              <span onClick={() => handleCartProductQuantity('inc', item)}>+</span>
            </div>
            <div className="text">
              <span>{item.quantity}</span>
              <span>x</span>
              <span className='highlight'>&#8377;{item.price}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CartItem