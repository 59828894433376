import React from 'react'
import './AdminPannel.css'

import { ColorModeContext, useMode } from '../../../theme';
import {CssBaseline, ThemeProvider} from '@mui/material';

import SlideBar from '../sellerDashBoard/scenes/global/slideBar/SlideBar'
import TopBar from '../sellerDashBoard/scenes/global/topBar/TopBar'

import Dashboard from '../sellerDashBoard/scenes/dashboard/DashBoard'
import BarChart from './scenes/bar/BarChart';
import Contacts from '../sellerDashBoard/scenes/contacts/Contacts'
import Faq from '../sellerDashBoard/scenes/faq/Faq'
import Form from '../sellerDashBoard/scenes/form/Form'
import Geography from '../sellerDashBoard/scenes/geography/Geography'
import Invoices from '../sellerDashBoard/scenes/invoices/Invoices'
import LineChart from './scenes/line/LineChart'
import PieChart from './scenes/pie/PieChart';
import Team from '../sellerDashBoard/scenes/team/Team'
import Calendar from '../sellerDashBoard/scenes/calendar/Calendar'
import { useState } from 'react';
import { Context } from '../../../utils/Context';

function AdminPanel() {

    const [colorMode, theme] = useMode();
    const [isSidebar, setIsSidebar] = useState(false);
    const {selected, setSelected} = React.useContext(Context);

    //render the selected component
    let Item;
    switch(selected){
        case 'Dashboard':
            Item = <Dashboard/>
            break;
        case 'Bar Chart':
            Item = <BarChart/>
            break;
        case 'Contacts Information':
            Item = <Contacts/>
            break;
        case 'FAQ Page':
            Item = <Faq/>
            break;
        case 'Profile Form':
            Item = <Form/>
            break;
        case 'Geography Chart':
            Item = <Geography/>
            break;
        case 'Invoices Balances':
            Item = <Invoices/>
            break;
        case 'Line Chart':
            Item = <LineChart/>
            break;
        case 'Pie Chart':
            Item = <PieChart/>
            break;
        case 'Manage Team':
            Item = <Team/>
            break;
        case 'Calendar':
            Item = <Calendar/>
            break;
        default:
            Item = <Dashboard/>
    }

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className='app'>
                    <SlideBar isSidebar={isSidebar}/>
                        <main className="content">
                            <TopBar setIsSidebar={setIsSidebar} />
                            {Item}
                        </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

export default AdminPanel