import React from 'react'
import { ResponsiveChoropleth } from '@nivo/geo'
import {useTheme} from '@mui/material'
import {tokens} from '../../../../theme'
import {geoFeatures} from '../../sellerDashBoard/utils/mockGeoFeatures'
import { mockGeographyData as data} from '../utils/mockData'

function Geo({isDashboard = false}) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <ResponsiveChoropleth
            data={data}
            theme={{
                // added
                axis: {
                  domain: {
                    line: {
                      stroke: colors.gray[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.gray[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.gray[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.gray[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.gray[100],
                  },
                },
            }}
            features={geoFeatures.features}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            domain={[ 0, 1000000 ]}
            unknownColor="#666666"
            label="properties.name"
            valueFormat=".2s"
            projectionTranslation={isDashboard ? [0.49, 0.6] : [ 0.5, 0.5 ]}
            projectionScale={isDashboard ? 40 : 150}
            projectionRotation={[ 0, 0, 0 ]}
            borderWidth={1.5}
            borderColor="#fff"
            legends={ !isDashboard ? 
            [
                {
                    anchor: 'bottom-left',
                    direction: 'column',
                    justify: true,
                    translateX: 20,
                    translateY: -100,
                    itemsSpacing: 0,
                    itemWidth: 94,
                    itemHeight: 18,
                    itemDirection: 'left-to-right',
                    itemTextColor: colors.gray[100],
                    itemOpacity: 0.85,
                    symbolSize: 18,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: colors.blueAccent[500],
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ] : undefined}
        />
    )
}

export default Geo