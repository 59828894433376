import React from 'react'
import './Products.scss'
import Product from './Product/Product'

function Products({innerPage, headingText, products}) {

  //map through the data and render the products
  const renderProducts = () => {
    return products?.map(item => {
      return (
        <Product
          key={item.id}
          id={item.id}
          title={item.name}
          price={item.price}
          image={item.images[0]}
        />
      )
    })
  }

  return (
    <div className='products--container'>
      {!innerPage && <div className="sec--heading">{headingText}</div>}
      <div className="products">
        {renderProducts()}
      </div>
    </div>
  )
}

export default Products