import React from 'react'
import './Details.scss'

function Details() {
  return (
    <div className='details--page'>
        <div className="details--container">
        </div>
    </div>
  )
}

export default Details