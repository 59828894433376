import React from 'react'

import { Box, IconButton, useTheme } from '@mui/material'
import { useContext } from 'react'
import { ColorModeContext, tokens } from '../../../../../../theme'
import {InputBase} from '@mui/material'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon  from '@mui/icons-material/DarkModeOutlined'
import NotificationsOutlinedIcon  from '@mui/icons-material/NotificationsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import SearchIcon from '@mui/icons-material/Search'


function TopBar() {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const colorMode = useContext(ColorModeContext)


  return (
    <Box display="flex" justifyContent="space-between" p={2}
    sx={{
      boxShadow:`0px 2.98256px 7.4564px ${colors.gray[1000]}`
    }}
    >
      <Box 
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="5px"
        boxShadow="0px 2.98256px 7.4564px rgba(0, 0, 0, 0.05)"
      >
        <InputBase sx={{ml:2, flex:1}} placeholder="Search"/>
        <IconButton sx={{p:1}} type="button">
          <SearchIcon/>
        </IconButton>
      </Box>

      <Box display="flex">

        <IconButton
          onClick={colorMode.toggleColorMode}
        >
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon/>
          ) : (
            <LightModeOutlinedIcon/> 
          )}
        </IconButton>

        <IconButton>
          <NotificationsOutlinedIcon/>
        </IconButton>

        <IconButton>
          <SettingsOutlinedIcon/>
        </IconButton>

        <IconButton>
          <PersonOutlinedIcon/>
        </IconButton>

      </Box>
    </Box>
  )
}

export default TopBar