import React from 'react'
import './Registration.scss'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import {db, auth} from '../../../utils/Firebase'



function Registration() {

  const [storeName, setStoreName] = useState()
  const [ownerName, setOwnerName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [address, setAddress] = useState()
  const [businessCategory, setBusinessCategory] = useState()
  const [taxId, setTaxId] = useState()
  const [productDescription, setProductDescription] = useState()
  const [shippingPolicy, setShippingPolicy] = useState()
  const [paymentMethod, setPaymentMethod] = useState()
  const [bankName, setBankName] = useState()
  const [branch, setBranch] = useState()
  const [ifscCode, setIfscCode] = useState()
  const [accountNumber, setAccountNumber] = useState()
  const [holderName, setHolderName] = useState()
  const [upiId, setUpiId] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [termsAgree, setTermsAgree] = useState(false)

  const navigate = useNavigate()

  const [selectedMethod, setSelectedMethod] = useState('');

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  //store ID
  const min = 100000; 
  const max = 999999; 
  const storeId = "SID" + Math.floor(Math.random() * (max - min + 1) + min) ;

  const writeToFirestore = () => {

    const user = auth.currentUser;

    const data = {
      new_orders: 0,
      s_ID: storeId,
      s_category: businessCategory,
      s_email: email,
      s_location: address,
      s_name: storeName,
      s_o_name: ownerName,
      s_o_uid: user.uid,
    };

    db.collection("Stores").doc(storeId).set(data)
      .then(() => {
        alert("Registration Successful!");
        navigate('/seller-dashboard')
      })
      .catch((error) => {
        alert("Error in Registration!", error);
      });
  }


  return (
    <div className='registration--page'>
      <div className="title">
        <h1 onClick={() => navigate('/')}>LESSOCOMMERSE</h1>
      </div>
      <div className="reg-form-container">
          <h1>Register Your Digital Store</h1>
          <form className="registration--form" method="POST">
            <div className="field">
              <span>Store Name</span>
              <input type="text" id="full-name" name="full-name" required 
                value={storeName}
                onChange={
                    (e) => setStoreName(e.target.value)
                }
              />
            </div>

            <div className="field">
              <span>Owner Name</span>
              <input type="text" id="full-name" name="full-name" required
                value={ownerName}
                onChange={
                    (e) => setOwnerName(e.target.value)
                }
            />
            </div>

            <div className="field">
              <span>Email</span>
              <input type="email" id="email" name="email" required
                value={email}
                onChange={
                    (e) => setEmail(e.target.value)
                }
              />
            </div>
            
            <div className="field">
              <span>Phone</span>
              <input type="tel" id="phone" name="phone" required
                value={phone}
                onChange={
                    (e) => setPhone(e.target.value)
                }
              />
            </div>

            <div className="field">
              <span>Address</span>
              <textarea id="address" name="address" required
                value={address}
                onChange={
                    (e) => setAddress(e.target.value)
                }
              ></textarea>
            </div>

            <div className="field">
              <span>Business Category</span>
              <input type="text" id="business-name" name="business-name"
                value={businessCategory}
                onChange={
                    (e) => setBusinessCategory(e.target.value)
                }
              />
            </div>

            <div className="field">
              <span>Tax ID (Optional)</span>
              <input type="text" id="tax-id" name="tax-id"/>
            </div>

            <div className="field">
              <span>Product Description (Optional)</span>
              <textarea id="product-description" name="product-description" required></textarea>
            </div>
            
            <div className="field">
              <span>Shipping Policy</span>
              <textarea id="shipping-policy" name="shipping-policy" required></textarea>
            </div>

          </form>

          <div className="payment--details">
            <h2 className="payment--title">Payment Details</h2>
            <span>Choose Payment Method</span>

            <div className="payment--methods">
              <div className="payment--method">
                <input
                  type="radio"
                  id="method-bank"
                  name="payment-method"
                  value="bank"
                  onChange={handleMethodChange}
                />
                <label htmlFor="method-bank">Bank Account</label>
              </div>

              <div className="payment--method">
                <input
                  type="radio"
                  id="method-upi"
                  name="payment-method"
                  value="upi"
                  onChange={handleMethodChange}
                />
                <label htmlFor="method-upi">UPI</label>
              </div>

              <div className="payment--method">
                <input
                  type="radio"
                  id="method-paytm"
                  name="payment-method"
                  value="paytm"
                  onChange={handleMethodChange}
                />
                <label htmlFor="method-paytm">Paytm</label>
              </div>

              <div className="payment--method">
                <input
                  type="radio"
                  id="method-gpay"
                  name="payment-method"
                  value="gpay"
                  onChange={handleMethodChange}
                />
                <label htmlFor="method-gpay">Google Pay</label>
              </div>
            </div>

            {selectedMethod === 'bank' && (
            <div className="method--bank method--details">
              <div className="field">
                <span>Bank Name</span>
                <input type="text" id="tax-id" name="tax-id"/>
              </div>

              <div className="field">
                <span>Branch</span>
                <input type="text" id="tax-id" name="tax-id"/>
              </div>

              <div className="field">
                <span>IFSC Code</span>
                <input type="text" id="tax-id" name="tax-id"/>
              </div>

              <div className="field">
                <span>Acc. No</span>
                <input type="text" id="tax-id" name="tax-id"/>
              </div>

            </div>
            )}

            {selectedMethod === 'upi' && (
            <div className="method--upi method--details">
                <div className="field">
                  <span>Holder Name</span>
                  <input type="text" id="tax-id" name="tax-id"/>
                </div>

                <div className="field">
                  <span>Upi Id</span>
                  <input type="text" id="tax-id" name="tax-id"/>
                </div>
            </div>
            )}

          </div>

          <div className="pass--field">
            <span>Password</span>
            <input 
              type="password" 
              id="password" 
              name="password" 
              required
            />
          </div>

          <div className="pass--field">
            <span>Confirm Password</span>
            <input type="password" id="password" name="password" required/>
          </div>

          <div className="check--field">
              <input className='checkbox' type="checkbox" id="terms-agree" name="terms-agree" required/>
              <span>I agree to the <a href="terms_and_conditions.html" target="_blank">Terms and Conditions</a>.</span>
          </div>

          <button onClick={() => writeToFirestore()}>Register</button>

      </div>

    </div>
  )
}

export default Registration