import React from 'react'
import './Banner.scss'
import { useNavigate } from 'react-router-dom'

function Banner() {

    const navigate = useNavigate()  

    return (
        <div className='seller--banner'>
            <div className="banner--container">
                <div className="banner--left">
                    <span>Limited Time Offer</span>
                    <h1>Become an seller</h1>
                    <p>More than half the units sold in our stores are from independent sellers.</p>
                    <button onClick={() => navigate('/become-a-seller/registration')}>Start selling</button>
                </div>
                <div className="banner--right">
                    <img src="https://m.media-amazon.com/images/G/31/amazonservices/landing/50_offer_May.webp" alt="" />
                </div>
            </div>
        </div>
    )
}

export default Banner